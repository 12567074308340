import { reactive } from 'vue'

export type AppSidebarIconState = 'visited' | 'unvisited'

const LOCALSTORAGE_KEY = 'app-sidebar-state'

type State = Record<string, AppSidebarIconState>

const cache = {
  get(): State | null {
    const data = localStorage.getItem(LOCALSTORAGE_KEY)
    if (!data) {
      return null
    }

    try {
      const state = JSON.parse(data)
      if (typeof state !== 'object') {
        return null
      }
      return state
    } catch (e) {
      return null
    }
  },

  save(data: State) {
    const saveString = JSON.stringify(data)
    localStorage.setItem(LOCALSTORAGE_KEY, saveString)
  },
}

const getDefaultState = (): State => {
  // TODO: will be removed after migration to new sidebar
  const state: State = {
    tenders: 'unvisited',
    sennfuel: 'unvisited',
  }
  return state
}

const getInitialState = (): State => {
  const cachedState = cache.get()

  return cachedState ?? getDefaultState()
}

const appSidebarIconState = reactive({ state: getInitialState() })

export const getAppSidebarState = () => appSidebarIconState.state

export const setVisited = (component: string) => {
  if (appSidebarIconState.state[component]) {
    appSidebarIconState.state[component] = 'visited'
    cache.save(appSidebarIconState.state)
  }
}
