<template>
  <div class="new-feature">
    <div class="inner-icon"></div>
    <div class="outer-icon"></div>
  </div>
</template>

<style scoped>
.new-feature {
  position: relative;
}

.outer-icon {
  position: absolute;
  width: 8px;
  top: 3px;
  right: calc(27% + 3px);
  z-index: 3;
  height: 8px;
  background: #1890ff;
  border-radius: 999px;
}

.inner-icon {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 0;
  right: calc(27%);
  opacity: 0.5;
  z-index: 2;
  background: #1890ff;
  border-radius: 999px;
}
</style>
