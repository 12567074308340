import { Navigation } from '@sennder/shell-utilities'

import { AppRoute } from './routes'
import {
  canAccessMarketplace,
  canAccessOnboarding,
  canAccessPayments,
  canAccessSennfuel,
  canAccessTenders,
} from './user-access'

export type AppNavigation = Navigation<AppRoute>

/**
 * Main menu items: categories, leafs (links) and widgets
 */
export const navigation: AppNavigation[] = [
  {
    kind: 'leaf',
    route: '/marketplace',
    icon: 'custom:marketplace',
    translationKey: 'sidebar.marketplace',
    visibilityHandlers: [canAccessMarketplace],
  },
  {
    kind: 'leaf',
    route: '/onboarding',
    icon: 'custom:marketplace',
    translationKey: 'sidebar.marketplace',
    visibilityHandlers: [canAccessOnboarding],
  },
  {
    kind: 'leaf',
    route: '/marketplace/tenders',
    icon: 'custom:marketplace',
    translationKey: 'sidebar.tenders',
    visibilityHandlers: [canAccessTenders],
  },
  {
    kind: 'leaf',
    route: '/assignments',
    icon: 'ClipboardPaste',
    translationKey: 'sidebar.assignments',
  },
  {
    kind: 'leaf',
    route: '/fleet',
    icon: 'Truck',
    translationKey: 'sidebar.fleet',
  },
  {
    kind: 'leaf',
    route: '/orders',
    icon: 'FileText',
    translationKey: 'sidebar.orders',
  },
  {
    kind: 'leaf',
    route: '/sennfuel',
    icon: 'Fuel',
    translationKey: 'sidebar.sennfuel',
    visibilityHandlers: [canAccessSennfuel],
  },
  {
    kind: 'leaf',
    route: '/payments',
    icon: 'Euro',
    translationKey: 'sidebar.payments',
    visibilityHandlers: [canAccessPayments],
  },
]
