import { AppRoute } from '@/config/routes'

// TODO: remove this function after migration to new sidebar
export const mapLegacyIcon = (route: AppRoute) => {
  switch (route) {
    case '/marketplace':
    case '/onboarding':
      return 'marketplace-icon.svg'
    case '/marketplace/tenders':
      return 'tenders-icon.svg'
    case '/assignments':
      return 'assignment-icon.svg'
    case '/fleet':
      return 'fleet-icon.svg'
    case '/orders':
      return 'orders-icon.svg'
    case '/sennfuel':
      return 'sennfuel-icon.svg'
    case '/payments':
      return 'payments-overview-icon.svg'
  }
  return ''
}
