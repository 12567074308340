import {
  ICookieConsent,
  ICookieFunctions,
} from '@sennder/senn-node-microfrontend-interfaces'

import errorsHandler from '@/services/errors-handler'
import carrierUserService from '@/services/carrierUserService'
import {
  getStateCallbacks,
  getStateData,
  getStateProviders,
} from '@/store/getters'
import { Cookies } from '@/types/types'
import { parseTenantFromToken } from './tokenManager'
import { microfrontends } from '@/config/microfrontends'

const DEFAULT_CONSENT: ICookieConsent = {
  ESSENTIAL: true,
  ANALYTICS: false,
}

const cookieModule = microfrontends.cookies

let cookieFunctions: ICookieFunctions

async function loadCookieFunctions() {
  if (!cookieFunctions) {
    cookieFunctions = await cookieModule.getModule<ICookieFunctions>(
      './cookiemfcomponentApp'
    )
  }
}

async function getCookieData() {
  const { language, user } = getStateData()
  const modalLanguage = user
    ? language
    : // use language from browser by default, because user is not loaded yet
      navigator.language.split('-')[0] || 'en'
  return {
    language: modalLanguage,
    tenant: await parseTenantFromToken(),
    logger: getStateProviders().logger,
    onUnhandledError: getStateCallbacks().onUnhandledError,
  }
}

export async function getCookieConsent() {
  try {
    await loadCookieFunctions()
    return await cookieFunctions.getCookieConsent(await getCookieData())
  } catch (error) {
    errorsHandler(error)
    return DEFAULT_CONSENT
  }
}

export async function promptCookieConsent() {
  try {
    await loadCookieFunctions()
    return cookieFunctions.promptCookieConsent(await getCookieData())
  } catch (error) {
    errorsHandler(error)
    return DEFAULT_CONSENT
  }
}

export async function syncCookieConsent() {
  try {
    const user = getStateData().user
    if (!user) {
      return
    }
    const userCookies = [...user.cookies]
    const consent = await getCookieConsent()
    if (!consent) {
      return
    }
    const consentCookies = consentToArray(consent)
    // if consent cookies are different from user cookies, update user cookies (both store and CUS)
    if (
      JSON.stringify(userCookies.sort()) !==
      JSON.stringify(consentCookies.sort())
    ) {
      user.cookies = consentCookies
      await carrierUserService.acceptCookies(consentCookies)
    }
  } catch (error) {
    errorsHandler(error)
  }
}

function consentToArray(consent: ICookieConsent) {
  const cookies: Cookies[] = []
  if (consent.ESSENTIAL) {
    cookies.push(Cookies.Essential)
  }
  if (consent.ANALYTICS) {
    cookies.push(Cookies.Analytics)
  }
  return cookies
}
