import { AppNavigationGuard } from '@sennder/shell-utilities'

import { i18n } from '@/services/i18n'
import { getStateData } from '@/store/getters'
import { notify } from '@/store/notify'
import { logout } from '@/store/logoutActions'
import { canAccessMarketplace, canAccessOnboarding } from './user-access'
import { getAuth0Token } from '@/services/tokenManager'
import { DEFAULT_ROUTE } from '@/common/constants'

/**
 * Router Navigation Guards
 *
 * NAMING CONVENTIONS
 *
 * Route guard name should be a verb that describes what the guard does.
 *
 * - check{Page}Access: Check if the user has access to the page
 * - redirect{Page}: Redirect the user to a different page based on some criteria
 * - restrict{Criteria}: Restrict access to the page based on some criteria
 */

/**
 * Redirects authenticated users to the default route
 */
export const redirectAuthenticatedUser: AppNavigationGuard = async () => {
  const isAuthenticated = !!(await getAuth0Token())
  if (isAuthenticated) {
    return { path: DEFAULT_ROUTE }
  } else {
    return true
  }
}

/**
 * Restrict access to the page for non company carriers
 */
export const restrictForNonCompanyCarrier: AppNavigationGuard = async () => {
  if (getStateData().company?.isCarrier) {
    return true
  } else {
    notify(
      i18n.global.t('auth.unauthorized.is_carrier', {
        support: 'support@sennder.com',
      }),
      'error'
    )
    await logout({ callBackend: true })
    return false
  }
}

/**
 * Check if the user has already been onboarded, otherwise redirect to the Onboarding
 */
export const checkMarketplaceAccess: AppNavigationGuard = async () => {
  if (canAccessMarketplace()) {
    return true
  } else {
    return { path: '/onboarding' }
  }
}

/**
 * Check if the user has not yet been onboarded, otherwise redirect to the Marketplace
 */
export const checkOnboardingAccess: AppNavigationGuard = async () => {
  if (canAccessOnboarding()) {
    return true
  } else {
    return { path: '/marketplace' }
  }
}
