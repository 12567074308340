import {
  DestinationPlugin,
  Result,
  Event,
} from '@sennder/senn-node-analytics-frontend/lib/amplitude'

import { default as hj } from '@hotjar/browser'
declare type UserInfo = Record<
  string | number,
  string | number | Date | boolean
>

export const HotjarPluginName = 'hotjar'

export const HotjarPlugin: DestinationPlugin = {
  name: HotjarPluginName,
  type: 'destination',
  async execute(event: Event): Promise<Result> {
    if (hj.isReady()) {
      if (event.event_type === '$identify') {
        const { user_id, device_id, user_properties } = event
        const hotjarId = user_id || device_id || ''
        hj.identify(hotjarId, (user_properties as UserInfo) || {})
      } else {
        hj.event(event.event_type)
      }
      return {
        code: 0,
        event: event,
        message: 'Event forwarded to Hotjar API',
      }
    }

    return {
      code: 0,
      event: event,
      message: 'Hotjar not initialized',
    }
  },
}
