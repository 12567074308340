import { AppRouteMeta } from '@sennder/shell-utilities'

import { MicrofrontendId } from './microfrontends'
import {
  checkMarketplaceAccess,
  checkOnboardingAccess,
  restrictForNonCompanyCarrier,
} from './route-guards'
import {
  canAccessContracts,
  canAccessPayments,
  canAccessPlanner,
  canAccessProfile,
  canAccessSennfuel,
  canAccessSennfuelLp,
  canAccessTenders,
} from './user-access'

/**
 * Unique application route
 */
export type AppRoute = keyof typeof routes

export const TERMS_ROUTE_NAME = 'TermsAndConditions'
export const ONBOARDING_ROUTE_NAME = 'Onboarding'

/**
 * All routes of the application, ordered alphabetically by path.
 * Each route has navigation guards, analytical and logging contexts.
 */
export const routes = {
  '/assignments': {
    name: 'Assignments',
    mf: 'assignments',
    context: {
      analytics: {
        module: 'assignments',
        submodule: '',
      },
      logger: {
        codeOwners: 'matching',
        module: 'assignments-mf-component',
      },
    },
    guards: [restrictForNonCompanyCarrier],
  },
  '/contracts': {
    name: 'Contracts',
    mf: 'contracts',
    context: {
      analytics: {
        module: 'contracts',
        submodule: '',
      },
      logger: {
        codeOwners: 'asset-planning',
        module: 'chartering',
      },
    },
    guards: [restrictForNonCompanyCarrier, canAccessContracts],
  },
  '/fleet': {
    name: 'Fleet',
    mf: 'fleet',
    context: {
      analytics: {
        module: 'fleet',
        submodule: '',
      },
      logger: {
        codeOwners: 'transport-planning',
        module: 'fleet-mf-component',
      },
    },
    guards: [restrictForNonCompanyCarrier],
  },
  '/marketplace': {
    name: 'Marketplace',
    mf: 'marketplace',
    context: {
      analytics: {
        module: 'marketplace',
        submodule: '',
      },
      logger: {
        codeOwners: 'pod-discovery',
        module: 'marketplace-mf-component',
      },
    },
    guards: [restrictForNonCompanyCarrier, checkMarketplaceAccess],
  },
  '/marketplace/tenders': {
    name: 'Tenders',
    mf: 'marketplace',
    context: {
      analytics: {
        module: 'tenders',
        submodule: '',
      },
      logger: {
        codeOwners: 'pod-discovery',
        module: 'marketplace-mf-component',
      },
    },
    guards: [
      restrictForNonCompanyCarrier,
      canAccessTenders,
      checkMarketplaceAccess,
    ],
  },
  '/onboarding': {
    name: ONBOARDING_ROUTE_NAME,
    mf: 'onboarding',
    context: {
      analytics: {
        module: 'onboarding',
        submodule: '',
      },
      logger: {
        codeOwners: 'onboarding',
        module: 'onboarding-component',
      },
    },
    guards: [restrictForNonCompanyCarrier, checkOnboardingAccess],
  },
  '/order-details/:idForStaff': {
    name: 'OrderDetails',
    mf: 'marketplace',
    context: {
      analytics: {
        module: 'order-details',
        submodule: '',
      },
      logger: {
        codeOwners: 'pod-discovery',
        module: 'marketplace-mf-component',
      },
    },
    guards: [restrictForNonCompanyCarrier],
  },
  '/orders': {
    name: 'Orders',
    mf: 'orders',
    context: {
      analytics: {
        module: 'orders',
        submodule: '',
      },
      logger: {
        codeOwners: 'transport-planning',
        module: 'transport-mf-component',
      },
    },
    guards: [restrictForNonCompanyCarrier],
  },
  '/payments': {
    name: 'Payments',
    mf: 'payments',
    context: {
      analytics: {
        module: 'payments',
        submodule: '',
      },
      logger: {
        codeOwners: 'order-fulfillment',
        module: 'payments-overview-mf-component',
      },
    },
    guards: [restrictForNonCompanyCarrier, canAccessPayments],
  },
  '/planner': {
    name: 'Planner',
    mf: 'planner',
    context: {
      analytics: {
        module: 'planner',
        submodule: '',
      },
      logger: {
        codeOwners: 'planning',
        module: 'planner-mf-component',
      },
    },
    guards: [restrictForNonCompanyCarrier, canAccessPlanner],
  },
  '/profile': {
    name: 'Profile',
    mf: 'profile',
    context: {
      analytics: {
        module: 'account',
        submodule: '',
      },
      logger: {
        codeOwners: 'onboarding',
        module: 'profile-mf-component',
      },
    },
    guards: [restrictForNonCompanyCarrier, canAccessProfile],
  },
  '/sennfuel': {
    name: 'SennFUEL',
    mf: 'sennfuel',
    context: {
      analytics: {
        module: 'sennfuel',
        submodule: '',
      },
      logger: {
        codeOwners: 'sennfuel',
        module: 'sennfuel-mf-component',
      },
    },
    guards: [restrictForNonCompanyCarrier, canAccessSennfuel],
  },
  '/sennfuel-lp': {
    name: 'SennFUELLP',
    mf: 'sennfuel',
    public: true,
    fullscreenLayout: true,
    context: {
      analytics: {
        module: 'sennfuel-lp',
        submodule: '',
      },
      logger: {
        codeOwners: 'sennfuel',
        module: 'sennfuel-mf-component',
      },
    },
    guards: [canAccessSennfuelLp],
  },
  '/tender-details/:id': {
    name: 'TenderDetails',
    mf: 'marketplace',
    context: {
      analytics: {
        module: 'tender-details',
        submodule: '',
      },
      logger: {
        codeOwners: 'pod-discovery',
        module: 'marketplace-mf-component',
      },
    },
    guards: [restrictForNonCompanyCarrier],
  },
  '/terms': {
    name: TERMS_ROUTE_NAME,
    mf: 'terms',
    context: {
      analytics: {
        module: 'terms',
        submodule: '',
      },
      logger: {
        codeOwners: 'vetting',
        module: 'terms',
      },
    },
    guards: [restrictForNonCompanyCarrier],
  },
} as const satisfies {
  [TRoute: string]: AppRouteMeta<MicrofrontendId>
}
