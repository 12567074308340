import { UserAccessHandler } from '@sennder/shell-utilities'
import { getStateData, getStateFeatureFlags } from '@/store/getters'

/**
 * Access handlers are functions which return a boolean. They can be used as menu visibility handlers, and called in route guards.
 *
 * NAMING CONVENTIONS
 *
 * Access handler name should be a sentence which describes what access it checks, or user role it checks.
 *
 * - userIs{Role}: Check if the user has a specific role
 * - canAccess{Page}: Check if the user has access to the page
 */

export const canAccessMarketplace: UserAccessHandler = () =>
  !getStateData().hasOnboardingFormAccess

export const canAccessOnboarding: UserAccessHandler = () =>
  !!getStateData().hasOnboardingFormAccess

export const canAccessTenders: UserAccessHandler = () =>
  !!getStateFeatureFlags()['ENABLE_TENDERING-SIDE-MENU']

export const canAccessPlanner: UserAccessHandler = () =>
  !!getStateFeatureFlags()['microfrontend-planner']

export const canAccessProfile: UserAccessHandler = () =>
  !!getStateFeatureFlags()['microfrontend-profile']

export const canAccessSennfuel: UserAccessHandler = () =>
  !!getStateFeatureFlags()['sennfuel-microfrontend']

export const canAccessSennfuelLp: UserAccessHandler = () =>
  !!getStateFeatureFlags()['lp-sennfuel-microfrontend']

export const canAccessContracts: UserAccessHandler = () =>
  !!getStateFeatureFlags()['chartering']

export const canAccessPayments: UserAccessHandler = () =>
  !!getStateFeatureFlags()['payments-overview-microfrontend']
