import { RouteRecordRaw } from 'vue-router'
import { redirectAuthenticatedUser } from '@/config/route-guards'

export default [
  {
    path: '/auth',
    name: 'Auth',
    meta: {
      public: true,
      fullscreenLayout: true,
      guards: [redirectAuthenticatedUser],
      analytics: { module: 'auth', submodule: '' },
    },
    children: [
      {
        path: ':catchAll(.*)',
        name: 'Auth',
        meta: {
          analytics: { module: 'auth', submodule: '' },
          public: true,
          fullscreenLayout: true,
        },
        component: () => import('./AuthMicrofrontend.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      analytics: { module: 'auth', submodule: '' },
      public: true,
      fullscreenLayout: true,
      guards: [redirectAuthenticatedUser],
    },
    component: () => import('./AuthMicrofrontend.vue'),
  },
  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    meta: {
      analytics: { module: 'auth', submodule: '' },
    },
    component: () => import('./AuthMicrofrontend.vue'),
  },
] satisfies RouteRecordRaw[]
