import { Tenant } from '@sennder/senn-node-microfrontend-interfaces'
import { parseTenantFromToken } from './tokenManager'

interface ITenantPermission {
  tenants: Array<Tenant>
  featureFlags: Array<string>
}

export enum TenantPermissions {
  exampleWithFF = 'exampleWithFF',
  showProfileTeamTab = 'showProfile',
  showAnalyticsAndCookiesConsent = 'showAnalyticsAndCookiesConsent',
}

const tenantsPermissions: { [key: string]: ITenantPermission } = {
  [TenantPermissions.exampleWithFF]: {
    tenants: [Tenant.DEMO],
    featureFlags: ['example-feature-flag'],
  },
  [TenantPermissions.showProfileTeamTab]: {
    tenants: [Tenant.SENNDER, Tenant.POSTE, Tenant.DEMO],
    featureFlags: [],
  },
  [TenantPermissions.showAnalyticsAndCookiesConsent]: {
    tenants: [Tenant.SENNDER, Tenant.POSTE],
    featureFlags: [],
  },
}

export const getCurrentTenantPermission = async (
  permission: TenantPermissions,
  featureFlags: Record<string, any>
): Promise<boolean> => {
  const currentPermission = tenantsPermissions[permission]
  if (currentPermission) {
    const tenantAllowed = currentPermission.tenants.includes(
      await parseTenantFromToken()
    )
    if (tenantAllowed) {
      const hasFeatureFlags = currentPermission.featureFlags.length > 0
      if (hasFeatureFlags) {
        return currentPermission.featureFlags.every((flag) => {
          return featureFlags[flag]
        })
      }
      return true
    }
  }
  return false
}
