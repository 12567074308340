import { amplitude } from '@sennder/senn-node-analytics-frontend'
import { AmplitudeLogLevel } from '@sennder/senn-node-analytics-frontend/lib/types'
import { AppAmplitudeLogger } from '@sennder/shell-utilities'

import { AMPLITUDE_API_KEY, isLocalEnv, isUatEnv } from '@/common/config'
import { getStateData, getStateCompany, getStateUser } from '@/store/getters'
import { executeSafelyWithLogging } from './errors-handler'
import { logger } from './logger/loggers'
import { parseTenantFromToken } from './tokenManager'

export function initAnalytics() {
  return executeSafelyWithLogging(() => {
    if (isUatEnv()) {
      logger.warn(
        '[orcas-shell - Analytics]: Amplitude is not available in UAT environment',
        {}
      )
      return
    }
    if (!AMPLITUDE_API_KEY) {
      throw new Error('[orcas-shell - Analytics]: AMPLITUDE_API_KEY not found')
    }

    amplitude.init({
      token: AMPLITUDE_API_KEY,
      appName: 'orcas',
      amplitudeConfig: {
        minIdLength: 4,
        loggerProvider: new AppAmplitudeLogger(logger),
        logLevel: AmplitudeLogLevel.Warn,
        autocapture: {
          attribution: {
            excludeReferrers: [],
            initialEmptyValue: '',
          },
          pageViews: false,
          sessions: false,
          formInteractions: false,
          fileDownloads: false,
          elementInteractions: false,
        },
      },
    })
  }, 'amplitude.init')
}

export async function identifyUserInAnalytics(onLogin: boolean) {
  const analyticsFeaturesFlags = Object.fromEntries(
    Object.entries(getStateData().featureFlags).filter(([flag]) =>
      flag.startsWith('exp')
    )
  )
  const user = getStateUser()
  const company = getStateCompany()
  const cpsCarrier = getStateData().cpsCarrier
  const isInternalUser =
    user.email.endsWith('@sennder.com') || user.email.endsWith('@sennder.it')

  const analyticsUser = {
    tenant: await parseTenantFromToken(),
    carrierId: company.carrierId,
    userId: String(user.id),
    carrierType: cpsCarrier?.type,
    sennderSubsidiaryId: cpsCarrier?.billing?.sennder_subsidiary_id,
    language: user.language,
    hasOrcasAccess: getStateData().hasOrcasAccess,
    isInternalUser,
    ...analyticsFeaturesFlags,
  }

  const deprecatedUserProps: string[] = [
    'initial_dclid',
    'initial_fbclid',
    'initial_gbraid',
    'initial_gclid',
    'initial_ko_click_id',
    'initial_li_fat_id',
    'initial_msclkid',
    'initial_referrer',
    'initial_referring_domain',
    'initial_rtd_cid',
    'initial_ttclid',
    'initial_twclid',
    'initial_utm_campaign',
    'initial_utm_content',
    'initial_utm_id',
    'initial_utm_medium',
    'initial_utm_source',
    'initial_utm_term',
    'initial_wbraid',
    'referrer',
    'referring_domain',
  ]

  executeSafelyWithLogging(
    () =>
      amplitude.identifyUser(
        analyticsUser,
        onLogin,
        {
          groupType: 'carrier id',
          groupValue: `${analyticsUser.carrierId}`,
        },
        deprecatedUserProps
      ),
    'amplitude.identifyUser'
  )
}

export const resetAnalyticsSession = () => {
  stopSessionReplay()
  executeSafelyWithLogging(() => amplitude.endSession(), 'amplitude.endSession')
  initAnalytics()
}

// Used when the user rejects analytics cookies,
// all user data will be removed from the analytics integration as well as any event submissions will be terminated
export const stopAnalytics = () => {
  executeSafelyWithLogging(
    () => amplitude.stopTracking(),
    'amplitude.stopTracking'
  )
}

export function initSessionReplay() {
  if (isLocalEnv()) {
    executeSafelyWithLogging(
      () =>
        amplitude.initSessionReplay({
          sampleRate: 0.8,
        }),
      'amplitude.initSessionReplay'
    )
  }
}

export function stopSessionReplay() {
  if (isLocalEnv()) {
    executeSafelyWithLogging(
      () => amplitude.stopSessionReplay(),
      'amplitude.stopSessionReplay'
    )
  }
}
