import { watch } from 'vue'
import { RouteLocationNormalized, RouteMeta, Router } from 'vue-router'

import { IOrcasSharedData } from '@sennder/senn-node-microfrontend-interfaces'
import {
  AppNavigationGuard,
  AppRouteMeta,
  ComponentDefinition,
  createMfWrapper,
  MicroFrontendLogger,
} from '@sennder/shell-utilities'
import { FederatedModule } from '@sennder/federated-module-loader'

import { authRoutes } from '@/modules/auth'
import { signupRoutes } from '@/modules/signup'
import { analytics, AppAnalyticsProvider } from '@/services/analyticsProvider'
import {
  getStateCallbacks,
  getStateData,
  getStateProviders,
} from '@/store/getters'
import router from '@/router'
import { logger } from '@/services/logger/loggers'
import { getMicrofrontendData } from '@/store'
import { isLocalEnv } from '@/common/config'
import { loggerInstance } from '@/services/logger'
import { AppRoute, routes } from '@/config/routes'
import { getAuth0Token } from '@/services/tokenManager'
import { DEFAULT_ROUTE } from '@/common/constants'
import { microfrontends } from '@/config/microfrontends'

export const initRouter = async () => {
  await registerRoutes()
  initRouteGuards(router)
}

const featureFlagWatcher = async () =>
  watch(
    () => getStateData().featureFlags,
    async () => {
      const currentRoute = router.currentRoute.value
      if (!currentRoute) {
        return
      }
      // re-execute navigation guards for current route
      const result = await routeNavigationGuard(currentRoute, currentRoute)
      if (result !== true) {
        if (result === false) {
          router.push(DEFAULT_ROUTE)
        } else {
          router.push(result)
        }
      }
    }
  )

const setPageTitle = (name: string, meta: RouteMeta): void => {
  // TODO: need to translate page titles in POEditor
  const pageName = meta.title || name.charAt(0).toUpperCase() + name.slice(1)
  document.title = `${pageName} |  Orcas - sennder`
}

/**
 * Micro-frontends with routes
 */
type AppRouteMicrofrontendId = (typeof routes)[AppRoute]['mf']

const createRouteComponent = async (
  module: FederatedModule<IOrcasSharedData>,
  routeMeta: AppRouteMeta<AppRouteMicrofrontendId>
) => {
  const analyticsProvider = new AppAnalyticsProvider(
    routeMeta.context.analytics
  )
  const mfLogger = new MicroFrontendLogger(
    routeMeta.context.logger,
    () => loggerInstance
  )

  return await createMfWrapper<IOrcasSharedData>({
    allowEnvironmentOverrides: isLocalEnv(),
    getData: async () => {
      return {
        data: await getMicrofrontendData(module.npmName),
        callbacks: getStateCallbacks(),
        providers: getStateProviders(),
      }
    },
    hooks: {
      failure: logger.error,
    },
    mf: {
      id: module.npmName,
      fml: module,
      context: {
        analytics: routeMeta.context.analytics,
        logger: routeMeta.context.logger,
      },
    },
    providers: {
      getAnalytics: () => analyticsProvider,
      getLogger: () => mfLogger,
    },
    router,
    watchers: [featureFlagWatcher],
  })
}

const addAppRoute = (
  routePath: AppRoute,
  routeMeta: AppRouteMeta<AppRouteMicrofrontendId>,
  component: ComponentDefinition
) => {
  const module = microfrontends[routeMeta.mf]

  const guards = routeMeta.guards || []

  router.addRoute({
    path: routePath,
    name: routeMeta.name,
    component,
    meta: {
      public: routeMeta.public,
      fullscreenLayout: routeMeta.fullscreenLayout,
      guards,
      npmName: module.npmName,
      // TODO: need to translate page titles in POEditor
      // title: `navigation.item.${routeMeta.name}`,
      analytics: routeMeta.context.analytics,
    },
    children: [
      {
        path: ':catchAll(.*)',
        name: routeMeta.name,
        meta: {
          guards,
          npmName: module.npmName,
        },
        component,
      },
    ],
  })
}

const registerRoutes = async () => {
  router.addRoute({
    path: '/',
    redirect: { path: '/marketplace' },
    meta: {
      guards: [
        () => {
          // TODO: maybe better find first visible page in menu?
          if (getStateData().hasOnboardingFormAccess) {
            return { path: '/onboarding' }
          }
          return true
        },
      ],
    },
  })

  for (const route of authRoutes) {
    router.addRoute(route)
  }

  for (const route of signupRoutes) {
    router.addRoute(route)
  }

  const componentMap = new Map<string, ComponentDefinition>()

  for (const [path, route] of Object.entries(routes)) {
    const module = microfrontends[route.mf]

    let component = componentMap.get(module.npmName)
    if (!component) {
      component = await createRouteComponent(module, route)
      componentMap.set(module.npmName, component)
    }

    addAppRoute(path as AppRoute, route, component)
  }

  router.addRoute({
    path: '/:catchAll(.*)',
    name: 'not-found',
    meta: {
      public: true,
      fullscreenLayout: true,
    },
    component: () => import('../NotFound.vue'),
  })
}

const initRouteGuards = (router: Router) => {
  router.beforeEach((to, from) => {
    return routeNavigationGuard(to, from)
  })

  router.beforeResolve((to) => {
    const { name, meta } = to
    setPageTitle(String(name), meta)

    const PAGE_VIEW_EXCLUDE_COMPONENTS = ['marketplace', 'profile-mf-component']

    // TODO: remove automatic trackPage() calls when all mFs track their own pages
    const module = meta.npmName
    if (
      (!module || !PAGE_VIEW_EXCLUDE_COMPONENTS.includes(module)) &&
      meta.analytics
    ) {
      analytics.trackPage(meta.analytics.module, {
        module: meta.analytics.module,
      })
    }
  })
}

const isAuthenticatedGuard: AppNavigationGuard = async (
  to: RouteLocationNormalized
) => {
  const isAuthenticated = !!(await getAuth0Token())
  if (isAuthenticated) {
    return true
  } else {
    return {
      path: '/login',
      query: { redirectTo: encodeURIComponent(to.fullPath) },
    }
  }
}

const routeNavigationGuard: AppNavigationGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) => {
  const authGuards = to.meta.public ? [] : [isAuthenticatedGuard]
  const guards = [...authGuards, ...(to.meta.guards || [])]
  if (guards.length === 0) {
    return true
  }

  for (const guard of guards) {
    const result = await guard(to, from)
    if (
      typeof result === 'object' ||
      typeof result === 'string' ||
      result === false
    ) {
      return result
    }
  }

  return true
}
