import {
  MicrofrontendEvents,
  onAcceptTermsAndConditions,
  subscribe,
} from '@sennder/senn-node-microfrontend-event-bus'

import { getStateUser } from '@/store/getters'
import router from '@/router'
import { logout } from '@/store/logoutActions'
import { TERMS_ROUTE_NAME } from '@/config/routes'

const handleTermsResponse = async (payload: onAcceptTermsAndConditions) => {
  if (!payload.accepted) {
    await logout()
    return
  }

  let routePath = '/'
  if (router.currentRoute.value.query.redirectTo) {
    routePath = router.currentRoute.value.query.redirectTo.toString()
  }
  router.push(routePath)
}

export const redirectToTerms = async () => {
  await router.isReady()

  const currentRoute = router.currentRoute.value
  const routeName = currentRoute.name?.toString() || ''

  if (routeName !== TERMS_ROUTE_NAME) {
    await router.push({
      name: TERMS_ROUTE_NAME,
      query: {
        redirectTo: currentRoute.fullPath.toString(),
      },
    })
  }
}

/**
 * Checks if the user has accepted the latest terms or needs to provide marketing consent,
 * and redirects to the terms page if necessary.
 *
 * This function performs the following checks:
 * 1. Retrieves the current user state.
 * 2. Determines if the user needs to accept marketing opt-in.
 * 3. Determines if the marketing opt-in modal should be shown.
 * 4. Redirects to the terms page if the user has not accepted the latest terms or if the marketing opt-in modal should be shown.
 */
export const checkUserTermsOrConsentAndRedirect = () => {
  const user = getStateUser()
  const consent = user.consent

  // Redirect the user only if he has not accepted or declined marketing emails
  const shouldShowMarketingOptInModal =
    consent && consent.marketingOptInAt === null

  if (!user.hasAcceptedLatestTerms || shouldShowMarketingOptInModal) {
    redirectToTerms()
  }
}

export const registerModalManager = () => {
  subscribe<onAcceptTermsAndConditions>(
    MicrofrontendEvents.onAcceptTermsAndConditions,
    (payload) => handleTermsResponse(payload)
  )
}
