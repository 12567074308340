import { CacheType, FederatedModule } from '@sennder/federated-module-loader'
import {
  INotificationCenterData,
  IOrcasSharedData,
} from '@sennder/senn-node-microfrontend-interfaces'

import { ENV } from '@/common/config'
import { logger } from '@/services/logger/loggers'

const cacheType: CacheType = 'infinite'

/**
 * Unique micro-frontend identifier
 */
export type MicrofrontendId = keyof typeof microfrontends

/**
 * All unique micro-frontends which can be loaded in the application
 */
export const microfrontends = {
  auth: new FederatedModule<any>({
    devPort: 9103,
    environment: ENV,
    moduleFederationPluginName: 'authmfcomponent',
    npmName: 'auth-mf-component',
    mountExposedName: './authmfcomponentApp',
    logger,
    cacheType,
  }),
  signup: new FederatedModule<any>({
    devPort: 9107,
    environment: ENV,
    moduleFederationPluginName: 'carriersignupmfcomponent',
    npmName: 'carrier-signup-mf-component',
    mountExposedName: './carriersignupmfcomponentApp',
    logger,
    cacheType,
  }),
  marketplace: new FederatedModule<IOrcasSharedData>({
    devPort: 9104,
    environment: ENV,
    moduleFederationPluginName: 'marketplacemfcomponent',
    npmName: 'marketplace-mf-component',
    mountExposedName: './marketplacemfcomponentApp',
    logger,
    cacheType,
  }),
  onboarding: new FederatedModule<IOrcasSharedData>({
    devPort: 9106,
    environment: ENV,
    moduleFederationPluginName: 'onboardingcomponent',
    npmName: 'onboarding-component',
    mountExposedName: './onboardingcomponentApp',
    logger,
    cacheType,
  }),
  assignments: new FederatedModule<IOrcasSharedData>({
    devPort: 9108,
    environment: ENV,
    moduleFederationPluginName: 'assignmentsmfcomponent',
    npmName: 'assignments-mf-component',
    mountExposedName: './assignmentsmfcomponentApp',
    logger,
    cacheType,
  }),
  planner: new FederatedModule<IOrcasSharedData>({
    devPort: 9198,
    environment: ENV,
    moduleFederationPluginName: 'plannermfcomponent',
    npmName: 'planner-mf-component',
    mountExposedName: './plannermfcomponentApp',
    logger,
    cacheType,
  }),
  fleet: new FederatedModule<IOrcasSharedData>({
    devPort: 9199,
    environment: ENV,
    moduleFederationPluginName: 'fleetmfcomponent',
    npmName: 'fleet-mf-component',
    mountExposedName: './fleetmfcomponentApp',
    logger,
    cacheType,
  }),
  orders: new FederatedModule<IOrcasSharedData>({
    devPort: 9110,
    environment: ENV,
    moduleFederationPluginName: 'transportmfcomponent',
    npmName: 'transport-mf-component',
    mountExposedName: './transportmfcomponentApp',
    logger,
    cacheType,
  }),
  profile: new FederatedModule<IOrcasSharedData>({
    devPort: 9105,
    environment: ENV,
    moduleFederationPluginName: 'profilemfcomponent',
    npmName: 'profile-mf-component',
    mountExposedName: './profilemfcomponentApp',
    logger,
    cacheType,
  }),
  sennfuel: new FederatedModule<IOrcasSharedData>({
    devPort: 8083,
    environment: ENV,
    moduleFederationPluginName: 'sennfuelmfcomponent',
    npmName: 'sennfuel-mf-component',
    mountExposedName: './sennfuelmfcomponentApp',
    logger,
    cacheType,
  }),
  contracts: new FederatedModule<IOrcasSharedData>({
    devPort: 8084,
    environment: ENV,
    moduleFederationPluginName: 'chartering',
    npmName: 'chartering',
    mountExposedName: './charteringApp',
    logger,
    cacheType,
  }),
  terms: new FederatedModule<IOrcasSharedData>({
    devPort: 9107,
    environment: ENV,
    moduleFederationPluginName: 'terms',
    npmName: 'terms',
    mountExposedName: './termsApp',
    logger,
    cacheType,
  }),
  payments: new FederatedModule<IOrcasSharedData>({
    devPort: 9104,
    environment: ENV,
    moduleFederationPluginName: 'paymentsoverviewmfcomponent',
    npmName: 'payments-overview-mf-component',
    mountExposedName: './paymentsoverviewmfcomponentApp',
    logger,
    cacheType,
  }),
  notifications: new FederatedModule<INotificationCenterData>({
    devPort: 9199,
    environment: ENV,
    moduleFederationPluginName: 'notificationcentermf',
    npmName: 'notification-center-mf',
    mountExposedName: './notificationcentermfApp',
    logger,
    cacheType,
  }),
  cookies: new FederatedModule<any>({
    devPort: 8087,
    environment: ENV,
    moduleFederationPluginName: 'cookiemfcomponent',
    npmName: 'cookie-mf-component',
    mountExposedName: './cookiemfcomponentApp',
    logger,
    cacheType,
  }),
} as const satisfies {
  [TMicroFrontend: string]:
    | FederatedModule<IOrcasSharedData>
    | FederatedModule<INotificationCenterData>
}
