import { RouteRecordRaw } from 'vue-router'
import { redirectAuthenticatedUser } from '@/config/route-guards'

// Todo: add feature flag for signup
export default [
  {
    path: '/signup',
    name: 'signup',
    meta: {
      analytics: { module: 'signup', submodule: '' },
      public: true,
      fullscreenLayout: true,
      guards: [redirectAuthenticatedUser],
    },
    component: () => import('./SignupMicrofrontend.vue'),
  },
  {
    path: '/signup-successful',
    name: 'signupSuccessful',
    meta: {
      analytics: { module: 'signup', submodule: '' },
      public: true,
      fullscreenLayout: true,
      guards: [redirectAuthenticatedUser],
    },
    component: () => import('./SignupMicrofrontend.vue'),
  },
] satisfies RouteRecordRaw[]
